a:hover,
a:active {
  color: rgb(34, 106, 228);
}

.customSwalBtn {
  background-color: rgb(34, 106, 228);
  border-left-color: rgb(34, 106, 228);
  border-right-color: rgb(34, 106, 228);
  border: 0;
  border-radius: 8px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin: 30px 5px 0px 5px;
  padding: 10px 32px;
}
.customSwalBtnClose {
  background-color: rgb(121, 126, 133);
  border-left-color: rgb(121, 126, 133);
  border-right-color: rgb(121, 126, 133);
  border: 0;
  border-radius: 8px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin: 30px 5px 0px 5px;
  padding: 10px 32px;
}
.customSwalBtnClose:hover,
.customSwalBtnClose:active {
  background-color: rgb(207, 26, 26) !important;
  border-left-color: rgb(207, 26, 26) !important;
  border-right-color: rgb(207, 26, 26) !important;
}

.badge {
  padding: 0.25em 0.55em !important;
  vertical-align: super !important;
  margin-left: -1em;
}
.c-status {
  display: inline-block;
  padding: 0.65em;
  width: 1.8em;
  height: 1.8em;
  vertical-align: middle;
  border-radius: 1.25rem;
}
.c-status:hover,
.c-status:active {
  cursor: pointer;
}
.c-color {
  display: inline-block;
  padding: 0.65em;
  width: 2.2em;
  height: 2.2em;
  vertical-align: middle;
  border-radius: 1.85rem;
}

#imglogo {
  width: 90%;
  max-width: 160px;
  margin: 0 auto !important;
}
#imglogosmall {
  width: 90%;
  margin: 0 auto !important;
}
.new-c {
  background-color: rgb(248, 252, 255) !important;
  color: rgb(34, 106, 228) !important;
  border-color: rgb(209, 209, 209) !important;
}
.new-c:hover,
.new-c:active {
  border-color: rgb(34, 106, 228) !important;
  color: #fff !important;
  background-color: rgb(34, 106, 228) !important;
}

.settings-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.settings-nav > a {
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
}

.dropdown-menu {
  border: 1px solid rgba(128, 128, 128, 0.08) !important;
  border-radius: 0rem !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 0.2rem;
}
.custom-tab-1 a.nav-item {
  text-transform: uppercase;
}
.custom-tab-1 a.active {
  background: transparent !important;
}
.custom-tab-1 a:hover {
  background: #fff !important;
}

body {
  background-color: #fff;
}
.dlabnav {
  background-color: rgb(248, 252, 255);
  border-right: 1px solid #ddd;
}
.nav-header,
.header {
  border: none !important;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
}
.nav-header {
  z-index: 7;
}

.header {
  height: 5.5rem !important;
}

.nav-header {
  height: 5.5rem !important;
}

.dlabnav {
  height: calc(100% - 5.5rem - 1px) !important;
  top: 5.5rem !important;
}

.hamburger {
  display: block;
  position: relative;
  width: 1.625rem;
  z-index: 999;
}

.hamburger .line {
  background: #717579;
  display: block;
  height: 0.1875rem;
  border-radius: 0.1875rem;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
  margin-right: auto;
  transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
  width: 1.625rem;
}

.hamburger .line:nth-child(2) {
  width: 1.625rem;
}

.hamburger .line:nth-child(3) {
  width: 0.9375rem;
}

.hamburger:hover .line {
  width: 1.625rem;
}

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 0.625rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(2) {
  transform: translateX(0px);
  width: 1.375rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(1) {
  transform: translateY(4px) translateX(12px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  transform: translateY(-4px) translateX(12px) rotate(-45deg);
}

.nav-control {
  cursor: pointer;
  position: absolute;
  left: 0;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  font-size: 1.4rem;
  padding: 0.125rem 0.5rem 0;
  border-radius: 0.125rem;
}

@media only screen and (max-width: 47.9375rem) {
  .nav-control {
    left: 2rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .nav-control {
    left: 2rem;
  }
}

@media only screen and (max-width: 63.9375rem) {
  .nav-header {
    width: 5rem;
    box-shadow: 0 0 0 0 #fff;
    display: flex;
  }
  .hamburger {
    left: -1.6rem;
  }
}

@media only screen and (min-width: 63.9376rem) {
  .nav-header .logo-abbr {
    display: none !important;
  }
}

@media only screen and (max-width: 63.9376rem) {
  .nav-header .brand-logo {
    justify-content: left;
    align-items: left;
  }
  .brand-logo .brand-title {
    margin-top: 0 !important;
    margin-left: -3px !important;
  }
  .nav-header #imglogo {
    display: none;
  }
  .header .header-content {
    padding-left: 1.8rem !important;
  }
  [data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
    left: -1rem !important;
  }
}

@media only screen and (min-width: 84.375rem) {
  .nav-header {
    width: 16.5rem;
  }

  .dlabnav {
    width: 16.5rem;
  }
  .container-fluid {
    margin-left: -2rem;
    margin-top: -2rem;
  }

  .header .header-content {
    margin-left: -4rem;
  }
}

.nav-header {
  max-width: 16.5rem;
}

.dlabnav {
  max-width: 16.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .dlabnav
  .metismenu
  > li.mm-active
  > a {
  font-weight: 600;
  background: var(--rgba-primary-1);
  border-bottom-right-radius: 5rem;
  border-top-right-radius: 5rem;
  max-width: 16rem;
}

.form-row {
  display: block;
  padding: 1rem;
  margin: 1rem 0;
  width: 100%;
}
.form-label {
  display: inline-block;
  width: 8rem;
  padding: 1rem;
  margin-right: 1rem;
  margin-bottom: 0;
  vertical-align: top;
}
.form-input,
.form-select-option {
  display: inline-block;
  width: calc(100% - 16rem);
  padding: 1rem;
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: #f9f9f9;
}
.form-select-option-data {
  display: inline-block;
  width: calc(100% - 16rem);
  padding: 0.5rem 1rem;
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: #f9f9f9;
}
.form-select-option-data span {
  margin-right: 6px;
}
.form-select-option-data span.s1 {
  margin-left: 30px;
}

.form-input-color {
  display: inline-block;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0;
}

.react-datepicker__input-container input {
  padding: 4px 10px;
  border: 1px solid lightgray;
  margin: 2px;
}
.form-input-color {
  display: inline-block;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0;
}
.form-buttons {
  display: inline-block;
  text-align: left;
}
.form-buttons button {
  margin: 0.2rem;
}
.form-buttons-right {
  float: right;
  margin-right: 6.8rem;
}
.form-buttons-right button {
  margin: 0.2rem;
}
.buttons-top-right {
  float: right;
  margin-right: 6.8rem;
}
.buttons-top-right button {
  margin: 0.2rem;
}
.form-image {
  display: inline-block;
  text-align: center;
}
.form-image img.brand-image {
  padding: 0.4rem;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 8px;
  margin: 0.5rem;
}
h2.tab-menu,
h3.tab-menu {
  font-size: 2.4em;
  border-left: 0.4rem solid rgb(34, 106, 228);
  padding-left: 0.4rem;
  display: block;
}
h3.tab-menu {
  margin-bottom: 1.5rem;
}
h2.tab-menu {
  margin-bottom: 3rem;
}
h4.tab-menu {
  border-left: 0.15rem solid rgb(34, 106, 228);
  padding-left: 0.2rem;
  display: block;
  margin-bottom: 1rem;
}
.footer {
  border-top: solid 0.05em #ddd;
  background-color: #fff;
}
.card2 {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: lightgrey;
  box-shadow: 1px 1px 2px lightgrey;
  padding: 0 !important;
  text-align: center !important;
}
.card3 {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: lightgrey;
  width: 100%;
  padding: 0 !important;
  text-align: center !important;
  margin-bottom: 2rem !important;
}
.card3 .img2 {
  padding: 0 !important;
}
.img2 img {
  width: 6rem;
  max-width: 60%;
  padding-top: 0.4rem;
}
.img2 p.card-text {
  line-height: 80%;
  padding: 0.5rem 0 !important;
  margin: 1rem 0 0 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.boxright {
  margin-top: 3rem;
}
.homebutton {
  border-radius: 8px;
  margin: 1rem 0;
  padding: 1rem !important;
  min-height: 8rem;
}

.homebutton p {
  font-size: 1.1rem;
  color: #fff;
  height: 100%;
}
.homebutton p span {
  font-size: 1.5rem;
}
.homebutton p span b {
  font-size: 1.6rem;
}
.brand-image {
  height: 6rem;
  max-width: 100%;
  object-fit: contain;
  padding: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.brand-image-menu {
  border-radius: 4px !important;
}
.brandEvent {
  height: 4rem;
  max-width: 80%;
  object-fit: contain;
  display: inline-block;
}

.btnEvent {
  background: #fff;
  border: 1px solid lightgray;
  border-radius: 0.6rem;
  padding: 0.2rem;
  text-align: center;
}
.action-on-img::after {
  display: none;
}
.action-on-img {
  font-size: 1.3em;
  padding: 0;
  margin: 0;
  background: transparent !important;
  border: none !important;
  color: #000 !important;
  position: absolute;
  left: 8px;
  top: 8px;
}
.sd-shape-home {
  width: 100%;
  transform: scale(1.1);
  position: relative;
  z-index: 1;
}

.cerca {
  text-align: left;
  border: 1px solid lightgrey;
  border-radius: 8px;
  margin-right: 1rem !important;
  background: url("search_icon.svg") no-repeat left center #fff;
  background-size: 2rem 2rem;
  text-indent: 0.6rem;
}

.mdb-datatable-filter input.form-control {
  border: 1px solid #e2e2e2 !important;
  padding: 0.03rem 0.5rem !important;
  height: 3em !important;
  border-radius: 2px !important;
  background: url("search_icon.svg") no-repeat left center #fff;
  background-size: 1.5rem 1.5rem;
  text-indent: 1rem;
}

.gestione-licenze button.aggiungi,
.gestione-licenze table td button,
.tabella-player a.aggiungi,
.tabella-player button.toggle-associati,
.tabella-player button.aggiungi,
.tabella_iniziative a.aggiungi,
.tabella_anagrafiche a.aggiungi,
.tabella_zone a.aggiungi,
.tabella_palinsesti a.aggiungi,
.tabella_categorie a.aggiungi,
.tabella_tag a.aggiungi,
.tabella_tipologie a.aggiungi,
.tabella_utenti a.aggiungi,
.tabella-anagrafiche {
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 0.4rem;
  width: 9rem;
  background-color: var(--primary) !important;
  color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.singolo_contenuto a.scarica {
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 0.4rem;
  width: 9rem;
  background-color: var(--primary) !important;
  color: #fff;
  text-align: center;
  text-decoration: none !important;
  position: absolute;
  right: 2rem;
  padding: 0.6rem;
}
.singolo_contenuto a.scarica:hover {
  background-color: var(--primary-hover) !important;
}
.tabella-player a.aggiungi,
.tabella-player button.aggiungi,
.tabella_iniziative a.aggiungi,
.tabella_anagrafiche a.aggiungi,
.tabella_zone a.aggiungi,
.tabella_palinsesti a.aggiungi,
.tabella_categorie a.aggiungi,
.tabella_tag a.aggiungi,
.tabella_tipologie a.aggiungi,
.tabella_utenti a.aggiungi,
.tabella-anagrafiche {
  position: absolute;
  right: 2rem;
  margin-top: -2.5rem !important;
}

.tabella_sub_anagrafiche a.sub_aggiungi {
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 0.4rem;
  width: 9rem;
  background-color: var(--primary) !important;
  color: #fff;
  text-align: center;
  text-decoration: none !important;
}

.tabella_sub_anagrafiche a.sub_aggiungi {
  position: absolute;
  right: 0.6rem;
  margin-top: -2.5rem !important;
}
.tabella_sub_anagrafiche .mdb-datatable-filter input.form-control {
  position: absolute;
  right: 10rem!important;
  margin-top: -2.5rem !important;
  border-radius: 8px !important;
}

.tabella-player button.toggle-associati {
  position: absolute;
  margin-top: -2.5rem !important;
  width:160px!important;
}
.gestione-licenze button.aggiungi {
  position: absolute;
  right: 2rem;
  margin-top: -2.5rem !important;
}
.gestione-licenze h4 {
  padding: 20px;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 3rem;
}
.gestione-licenze .mdb-datatable-filter input.form-control {
  position: absolute;
  right: 12rem;
  margin-top: -2.5rem !important;
}
.inserimento-licenze {
  padding: 20px;
}

.inserimento-licenze table th,
.inserimento-licenze table td {
  font-size: 90% !important;
  padding: 4px 10px !important;
}

.inserimento-licenze select.select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.inserimento-licenze .select {
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 8px;

  margin: 4px;
  width: calc(50% - 1rem);
  min-width: calc(50% - 1rem);
  padding: 0.5rem;
}
.inserimento-licenze .right {
  width: 100%;
  text-align: right;
  margin: 10px;
}
.inserimento-licenze .right button {
  margin: 2px;
}
.inserimento-licenze .tabella-codici {
  height: 250px;
  margin-bottom: 20px;
}
.inserimento-licenze .testo {
  margin: 20px auto;
}
/*
.tabella_contenuti select {width:calc(33% - 4rem);padding:0.5rem;background:rgb(235, 235, 235); margin:0.5rem 0.5rem 2rem 0;}
*/
.tabella_contenuti a.aggiungi {
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 0.4rem;
  width: 4rem;
  background-color: var(--primary) !important;
  color: #fff;
  position: absolute;
  right: 7rem;
  margin-top: -2.5rem !important;
  text-align: center;
  text-decoration: none !important;
}
.tabella_contenuti a.wizard {
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 0.4rem;
  width: 8rem;
  background-color: var(--primary) !important;
  color: #fff;
  position: absolute;
  right: 2rem;
  margin-top: -2.5rem !important;
  text-align: center;
  text-decoration: none !important;
}
.contenuti a.wizard {
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 0.4rem;
  width: 8rem;
  background-color: var(--primary) !important;
  color: #fff;
  position: absolute;
  right: 7rem;
  margin-top: -2.5rem !important;
  text-align: center;
  text-decoration: none !important;
}
.contenuti .mdb-datatable-filter input.form-control {
  position: absolute;
  right: 15.2rem !important;
  margin-top: -2.5rem !important;
  border-radius: 8px !important;
}
.contenuti a.indietro {
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 0.4rem;
  width: 5rem;
  background-color: var(--primary) !important;
  color: #fff;
  position: absolute;
  right: 2rem;
  margin-top: -2.5rem !important;
  text-align: center;
  text-decoration: none !important;
}
.contenuti .select-brand {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  margin-top: -2.5rem !important;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 8px;
  width: 24% !important;
  padding: 0.5rem;
  min-width: 100px;
}

.tabella_contenuti .mdb-datatable-filter input.form-control {
  position: absolute;
  right: 12rem;
  margin-top: -2.5rem !important;
  border-radius: 8px !important;
}

.tabella_contenuti .select-brand {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
  margin-top: -2.5rem !important;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 8px;

  width: 30%;
  padding: 0.5rem;
  min-width: 100px;
}

.tabella_contenuti .immagine_anteprima,
.tabella_contenuti .video_anteprima {
  height: 120px;
  width: 190px;
  background: transparent;
  text-align: center;
  margin: 0 auto;
  padding: 0;
}
.anteprima_inserimento {
  height: 100%;
  max-width: 240px;
  object-fit: contain;
}

.tabella_contenuti .immagine_anteprima img {
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  object-fit: contain;
}
.tabella_contenuti .video_anteprima video {
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.inserimento-contenuti {
  margin: 30px auto;
}
.inserimento-contenuti table {
  width: 100%;
  overflow-x: scroll;
}
.inserimento-contenuti .contenuti-files {
  margin: 30px auto;
  display: block;
}
.inserimento-contenuti table tr th {
  padding: 10px;
  margin: 3px;
  border: 1px solid lightgray;
  text-align: center;
}
.inserimento-contenuti table tr td {
  padding: 10px;
  margin: 3px;
  border: 1px solid lightgray;
}
.inserimento-contenuti table tr td input[type="text"] {
  width: 100%;
  border: 1px solid lightgrey;
  padding: 4px;
}
.inserimento-contenuti .select-brand {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin-top: 30px;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 8px;
  width: 32rem;
  padding: 0.5rem;
}

.btncontenuti {
  padding: 4px 12px;
  border-radius: 6px;
  display: inline;
  margin-left: 30px;
}
.inputcontenuti {
  display: inline;
}

.tabella_messaggi .bt1,
.tabella_messaggi .bt2,
.tabella_messaggi .bt3,
.tabella_messaggi .bt4,
.tabella_messaggi .bt5 {
  border: none;

  padding: 0;
  background-color: transparent !important;
  color: var(--primary);
  font-size: 1.5rem;
  margin-top: -2.5rem !important;
  text-align: center;
  text-decoration: none !important;
}
.tabella_messaggi .bt1:hover,
.tabella_messaggi .bt2:hover,
.tabella_messaggi .bt3:hover,
.tabella_messaggi .bt4:hover,
.tabella_messaggi .bt5:hover,
.tabella_messaggi .bt1:focus,
.tabella_messaggi .bt2:focus,
.tabella_messaggi .bt3:focus,
.tabella_messaggi .bt4:focus,
.tabella_messaggi .bt5:focus {
  color: rgb(1, 66, 230);
}

.tabella_messaggi .bt1 {
  position: absolute;
  right: 2.5rem;
}
.tabella_messaggi .bt2 {
  position: absolute;
  right: 4.5rem;
}
.tabella_messaggi .bt3 {
  position: absolute;
  right: 6.5rem;
}
.tabella_messaggi .bt4 {
  position: absolute;
  right: 8.5rem;
}
.tabella_messaggi .bt5 {
  position: absolute;
  right: 10.5rem;
}
.tabella_messaggi .mdb-datatable-filter input.form-control {
  position: absolute;
  margin-top: -2.5rem !important;
  border-radius: 8px !important;
  width: 16rem;
}

.tabella-player .mdb-datatable-filter input.form-control,
.tabella_iniziative .mdb-datatable-filter input.form-control,
.tabella_palinsesti .mdb-datatable-filter input.form-control,
.tabella_anagrafiche .mdb-datatable-filter input.form-control,
.tabella_categorie .mdb-datatable-filter input.form-control,
.tabella_utenti .mdb-datatable-filter input.form-control,
.tabella_tag .mdb-datatable-filter input.form-control,
.tabella_zone .mdb-datatable-filter input.form-control,
.tabella_tipologie .mdb-datatable-filter input.form-control {
  position: absolute;
  right: 12rem;
  margin-top: -2.5rem !important;
  border-radius: 8px !important;
}
.row-email {
  min-height: 40px;
  margin: 0;
  padding: 0;
}
.row-email-item-1 {
  width: 100%;
  min-height: 40px;
  margin: 0;
  display: flex;
  padding: 10px;
  color: #000 !important;
}
.row-email-item-0 {
  width: 100%;
  min-height: 40px;
  margin: 0;
  display: flex;
  padding: 10px;
  font-weight: 800;
  color: #000 !important;
}
.row-email-item-0 p,
.row-email-item-1 p {
  display: block !important;
}
.tabella_messaggi table tr {
  margin: 0 !important;
  padding: 0 !important;
}
.tabella_messaggi table tr td {
  margin: 0 !important;
  padding: 0 !important;
}

.tabelladati .mdb-datatable-filter input.form-control {
  position: absolute;
  right: 1rem;
  margin-top: -2.5rem !important;
  border-radius: 8px !important;
}
.tabella_dati_aziendali .mdb-datatable-filter input.form-control {
  position: absolute;
  right: 3.5vw;
  margin-top: -2.5rem !important;
  border-radius: 8px !important;
}
.tabella_dati_aziendali {
  margin-top: 4rem;
}

.tabella_tipologie .mdb-datatable-filter input.form-control {
  width: 14rem;
}

.nuovo-palinsesto input {
  width: calc(40% - 4rem - 4px);
  max-width: 400px;
  padding: 0.5rem;
  background: rgb(235, 235, 235);
  margin: 0.5rem 0.5rem 2rem 0;
  border: 1px solid lightgray;
  border-radius: 8px;
}

.nuovo-palinsesto select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 8px;
  width: calc(60% - 4rem - 4px);
  padding: 0.5rem;
  margin: 0.5rem 0.5rem 2rem 0;
  max-width: 400px;
}
select.select-anteprima,
select.select-anteprima-associa {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 8px;
}
select.select-anteprima {
  width: 100%;
  padding: 0.5rem;
}
select.select-anteprima-associa {
  width: calc(50% - 2.5rem);
  padding: 0.5rem;
  margin: 0.5rem;
  float: left;
}
.associa-player {
  padding: 2rem;
}
.associa-player em {
  padding-top: 3rem;
  display: block !important;
  font-size: 130%;
  font-style: normal !important;
}
.associa-player .form-label-associa {
  display: inline-block;
  width: 10rem;
  padding: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
  vertical-align: top;
}
.associa-player .form-input-associa {
  display: inline-block;
  width: calc(100% - 14rem);
  padding: 0.5rem;
  border: 1px solid lightgray;
  border-radius: 3px;
  background-color: #fff;
}
.nuovo-palinsesto .brand-disabled {
  filter: grayscale(100%) opacity(30%);
}

.modal-associabrand {
  width: 900px;
  max-width: 90vw;
}
div.associa-brand {
  max-height: 76vh;
  text-align: center;
  overflow: auto !important;
}

.app-dati {
  margin-top: 1rem;
}

img.tabellaimg {
  height: 6rem;
  background-color: #fff;
  padding: 0.5rem;
  border: 1px solid lightgray;
}

.tabella_anagrafiche table th + th + th + th + th,
.tabella_anagrafiche table tbody td + td + td + td + td {
  width: 100px !important;
  text-align: center;
}

.tabella_gestioneutenti table thead th + th + th + th + th + th + th,
.tabella_gestioneutenti table tbody td + td + td + td + td + td + td {
  text-align: center !important;
}
.tabella_tipologie table thead th + th + th,
.tabella_tipologie table tbody td + td + td,
.tabella_tag table thead th + th + th,
.tabella_tag table tbody td + td + td{
  text-align: center;
}

.tabella_tipologie table thead th + th + th + th,
.tabella_tipologie table thead td + td + td + td,
.tabella_tag table thead th + th + th + th,
.tabella_tag table thead td + td + td + td {
  width: 7rem !important;
  padding: 0.5rem;
}

.tabella_contenuti table thead th,
.tabella_contenuti table tbody td {
  text-align: left !important;
  width: 86% !important;
}

.tabella_contenuti table thead th + th,
.tabella_contenuti table tbody td + td {
  min-width: 120px !important;
}

.contenuti table thead th,
.contenuti table tbody td {
  text-align: left !important;
  max-width: 15%;
}

.contenuti table thead th + th + th + th,
.contenuti table tbody td + td + td + td {
  text-align: center !important;
  max-width: 10rem;
}

.categorie-image {
  width: 16rem;
  max-width: 100%;
  float: left;
  margin: 0.3em;
  border: 0.1rem solid #ddd;
  border-radius: 1rem;
  padding: 1rem;
  background-color: #fff;
}
.categorie-image img {
  width: 10rem;
}
.categorie-image .dropdown-toggle::after {
  display: none;
}
.categorie-image .dropdown-toggle {
  float: right;
  text-align: right;
  padding: 0;
  margin: 0;
  background: transparent !important;
  border: none !important;
  color: #000;
  width: 10rem;
}

.mdb-datatable a.page-link span {
  background: transparent !important;
  color: #444 !important;
  border-color: transparent !important;
}
.mdb-datatable a.page-link:hover span {
  color: #fff !important;
}
.mdb-datatable .dataTables_paginate ul.pagination li.page-item a.page-link {
  background: transparent;
  color: #666;
  border-color: transparent !important;
}
.mdb-datatable
  .dataTables_paginate
  ul.pagination
  li.page-item
  a.page-link:hover {
  background-color: rgb(34, 106, 228) !important;
  color: #fff !important;
  cursor: pointer;
  border-color: transparent !important;
}

.mdb-datatable .dataTables_paginate ul.pagination li.active a.page-link {
  background: rgb(34, 106, 228);
  box-shadow: 0 0 0 0;
  color: #fff !important;
  cursor: pointer !important;
  border-color: transparent !important;
}
.mdb-datatable li.disabled a.page-link span {
  background: transparent !important;
  color: #ddd !important;
  border-color: transparent !important;
}

.btnAggiungi {
  float: right;
  margin-bottom: -100px !important;
}

.linkvar {
  display: block;
  padding: 0.5rem 0 0.5rem 0.8rem;
}
.dropitem {
  margin: 0 !important;
  padding: 0 !important;
}
.mdb-datatable thead th {
  cursor: default !important;
}
.mdb-datatable thead th.sorting_desc,
.mdb-datatable thead th.sorting_asc {
  cursor: pointer !important;
}

div.table-dati {
  display: block;
  width: 100%;
}

div.table-dati > div {
  display: flex;
  width: 100%;

  flex-direction: horizonal;
}
div.table-dati > div > div {
  display: block;
  flex-grow: 1;

  background-color: rgb(244, 244, 244);
  padding: 0.75rem 0.5rem;
  margin: 0.5rem;
  border-radius: 8px;
  width: 33%;
}
div.table-dati > div > div input[type="text"],
div.table-dati > div > div select {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.2rem;
  border: none;
  display: block;
}

div.table-dati input[type="submit"] {
  float: right;
  margin-bottom: 0.5rem;
}

.dat2 {
  width: calc(66% + 1rem) !important;
}

.d_az label {
  display: block;
  margin-right: 0.3rem;
  text-transform: uppercase;
  padding: 0.2rem;
  border: 1px solid transparent;
  font-weight: bold;
}
.d_az span {
  display: block;
}

p.add-brand-list {
  font-size: 130%;
}
p.subtab {
  font-size: 130%;
  margin: 1rem 1rem 1rem 1rem;
}
p.pform {
  font-size: 130%;
  margin-top: 3rem;
}
.inline {
  display: inline;
}

.swal-icon img {
  max-height: 10rem;
}

div.nuovo-palinsesto ul {
  list-style-type: none;
}

div.nuovo-palinsesto li {
  display: inline-block;
}

div.nuovo-palinsesto input[type="checkbox"][id^="brand"] {
  display: none;
}

div.nuovo-palinsesto label {
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.4rem;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 8px;
  margin: 0.5rem;
  width: 20rem; 
  height: 15rem;
  text-align: center;
}
.fc-timegrid-slot-label {
  width: 100px !important;
}
div.nuovo-palinsesto label::before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 0; 
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 30px; 
  height: 30px; 
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

div.nuovo-palinsesto label img {
  height: 10rem; 
  max-width: 100%;
  object-fit: contain;
  padding: 10px;
  margin-top: auto;
  margin-bottom: auto;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}


div.nuovo-palinsesto :checked + label {
  border-color: rgb(40, 109, 224);
}

div.nuovo-palinsesto :checked + label::before {
  content: "✓";
  background-color: rgb(40, 109, 224);
  transform: scale(1);
}

div.nuovo-palinsesto :checked + label img {
  transform: scale(0.9);
  z-index: -1;
}

.step-bar {
  font-size: 1.2rem;
}
.nuovo-palinsesto {
  margin-top: 4rem;
}

.steps-actions {
  display: block;
  text-align: right;
  margin-bottom: 10px;
  clear: both;
}
.nav-steps .ant-steps-item-title {
  font-size: large;
}
.nav-steps .ant-steps-item-description {
  font-size: small;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: rgb(40, 109, 224) !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgb(40, 109, 224) !important;
}

.ant-btn {
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 6px;
  color: rgb(40, 109, 224) !important;
  border-color: rgb(40, 109, 224) !important;
  background: #fff;
  margin: 2px;
}
.ant-btn:hover {
  color: #1890ff !important;
  border-color: #1890ff !important;
}
.ant-btn-primary {
  color: #fff !important;
  border-color: rgb(40, 109, 224) !important;
  background: rgb(40, 109, 224) !important;
}

.ant-btn-primary:hover {
  color: #fff !important;
  background: #1890ff !important;
  border-color: #1890ff !important;
}

.fc {
  margin-top: -60px !important;
}

.templates {
  display: block;
  width: 100%;
}

.templates [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.templates label.template {
  float: left;
  padding: 0 !important;
  width: 200px !important;
  border: none;
}

.templates [type="radio"] + img {
  cursor: pointer;
  height: 150px;
  border: 2px solid lightgrey;
  border-radius: 6px;
  padding: 10px;
}

.templates [type="radio"]:checked + img {
  border: 2px solid rgb(38, 147, 251);
}

.table1 {
  text-align: left;
  color: #fff;
  font-size: 1.3rem;
  width: 100%;
}
.table1 td {
  padding: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: 30px;
}

.modal-body {
  padding: 0rem 0.5rem 1rem 0.5rem;
}

.form-compose input,
.form-compose textarea {
  border: 1px solid rgb(185, 185, 185);
  border-radius: 6px;
}
.form-compose textarea {
  overflow: auto;
  height: 150px;
}
.options {
  padding: 10px;
  border: 1px solid #999;
  height: 60px;
  overflow: auto;
}
.options label {
  display: block;
}
textarea.dettagli-body {
  width: 100%;
  padding: 20px;
  height: 300px;
  background: white;
  border: 1px solid lightgray;
}
.dettagli-messaggio-swal {
  max-height: 80vh;
  min-height: 60vh;
  overflow: auto;
  padding: 8px !important;
  text-align: left;
  border-bottom: 1px solid lightgray;
}
.dettagli-body a {
  color: rgb(1, 66, 230);
}
.dettagli-body a:hover {
  color: red;
}
.dettagli-body ul li {
  list-style-type: disc;
}
div.dettagli-messaggio {
  width: 98%;
  max-width: 1000px;
  max-height: 86vh;
  text-align: left;
  overflow: auto !important;
}
div.dettagli-oggetto {
  text-align: left;
  border-bottom: 1px solid lightgray;
  margin: 0;
  padding: 0;
}
div.dettagli-oggetto p.dettagli-data {
  text-align: right;
  font-size: 0.8em;
  color: #888;
}
p.dettagli-from span {
  font-size: 0.9em;
  color: #999;
}
div.dettagli-oggetto h4 {
  font-size: 1.3em;
}
.dettagli-messaggio form {
  width: 100%;
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.dettagli-messaggio form input[type="text"],
.dettagli-messaggio form textarea {
  width: 100%;
  margin: 4px;
  border: 1px solid lightgray;
  padding: 10px;
}
.dettagli-messaggio form input[type="checkbox"] {
  margin: 4px;
  vertical-align: middle;
}
.dettagli-messaggio form textarea {
  height: 150px;
}
.dettagli-messaggio select {
  position: relative !important;
  z-index: 9999 !important;
}
.dettagli-messaggio form label {
  text-transform: uppercase;
  color: #888;
  margin: 14px 0 0 0;
  font-size: 0.7em;
}
.dettagli-messaggio form input[type="radio"] {
  margin-right: 40px;
  margin-left: 8px;
  margin-bottom: 8px;
}
.dettagli-messaggio form h3 {
  margin: 0;
  font-size: 1.3em;
}
.dettagli-messaggio .opzioni {
  position: relative;
  z-index: 9999;
}
table.dettagli-contenuto {
  width: 100%;
  text-align: left;
  margin-top: 20px;
}
table.dettagli-contenuto tr th,
table.dettagli-contenuto tr td {
  padding: 5px;
  margin: 1px;
  border: 1px solid lightgray;
}
.swall-messaggi {
  width: 900px;
  max-width: 98%;
}
.impostazioni-messaggi .guida {
  margin-left: 18.8vw;
  border: 1px solid lightgray;
  height: 300px;
  max-height: 300px;
  overflow: auto;
  width: calc(100% - 18.8vw - 20px);
  padding: 1rem;
}

.div1 {
  display: inline-block;
}
.div2 {
  position: absolute;
  top: 0px;
  left: 0px;
  visibility: hidden;
  height: 0px;
  overflow: scroll;
  white-space: pre;
  font-size: 12.25px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
}

button.toggle-utente {
  color: rgb(57, 57, 57) !important;
  background-color: transparent !important;
  border: none;
  margin-left: -14px !important;
}
button.toggle-utente:hover,
button.toggle-utente:active {
  color: rgb(40, 109, 224) !important;
}

li.dropdown-utente button.toggle-utente::after {
  display: none !important;
}
div.menu-utente {
  border: 1px solid rgba(128, 128, 128, 0.08) !important;
  border-radius: 0rem !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0 0 0.2rem !important;
}

.modal-messages {
  width: 900px;
  max-width: 90vw;
}

.modal-messages label {
  font-size: 1em !important;
}
div.dettagli-messaggio form.messages {
  padding: 20px;
}
.buttonmodal {
  margin: 2px;
}

.modal-inserimento {
  width: 700px;
  max-width: 90vw;
  text-align: center;
}
.modal-inserimento h3 {
  text-align: center;
  width: 100%;
}
.modal-inserimento #loadStatus {
  font-size: 1.2em;
  text-align: right;
  padding: 20px;
}
p.sectionDescription {
  font-size: 130%;
}
.fadeOut{
  opacity:0;
  -webkit-animation: fadeout .3s linear forwards;
  animation: fadeout .3s linear forwards;

}
.fadeIn{
  opacity:1;
  -webkit-animation: fadein .3s linear forwards;
  animation: fadein .3s linear forwards;

}
@-webkit-keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
  
@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
  
@-webkit-keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
  
@keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; }
}